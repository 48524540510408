.signin_container {
    max-width: 1000px;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    align-content: center;
}

.signin_word {
    /* font-size: 2.5rem;
    /* color: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem; 
    align-items: center;
    /* padding: 2rem; */
    /* margin: auto; */
    align-self: center;
}

.signin_header {
    font-size: 3rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    /* color: #fff; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 48px;
    text-align: center;
    letter-spacing: 1px;
    color: #141416;
}

.signin_title {
    font-size: 1rem;
    font-weight: 400;
    /* color: #fff; */
    margin-bottom: 0.3rem;
    color: #777E90;
    font-size: 16px;
    line-height: 24px;
    word-spacing: 0.6px;
    letter-spacing: 0.3px;
}

.signin_label {
    text-transform: uppercase;
    font-size: 14px;
}

.signin_input {
    display: block;
    margin-top: 7px;
    margin-bottom: 20px;
    padding: 0.6rem;
    font-size: 1rem;
    border-radius: 10px;
    outline: none;
    border: 2px solid #ccc;
    width: 500px;
    color: #000;
    transition: all 0.3s ease-in-out; 

}

.signin_input:focus {
    border: 2px solid #252748;
    transition: all 0.3s ease-in-out;
}

/* .signin_input:hover {
    border: 2px solid #252748;
    transition: all 0.3s ease-in-out;
} */

.signin_button_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
    align-self: center;
}

.signin_button {
    display: block;
    margin-top: 7px;
    margin-bottom: 25px;
    padding: 0.8rem;
    font-size: 1rem;
    border-radius: 40px;
    outline: none;
    /* border: 2px solid #ccc; */
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
    width: 170px;
    height: 48px;
    background: #92A5EF;
    border-radius: 90px;
    border: none;
    outline: none;
    cursor: pointer;
}

.signin_button:hover {
    background: #677ed8;
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
}

.external_signin_button {
    /* display: flex; */
    margin-bottom: 1.5rem;
}

.signin_google_button {
    display: inline-block;
    margin-top: 7px;
    margin-right: 20px;
    margin-bottom: 25px;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 40px;
    outline: none;
    /* border: 2px solid #ccc; */
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
    width: 150px;
    height: 48px;
    background: #3B71FE;
    border: none;
    outline: none;
    cursor: pointer;
}

.signin_google_button:hover {
    background: #1c1c6e;
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
}

.signin_facebook_button {
    display: inline-block;
    margin-top: 7px;
    margin-right: 20px;
    margin-bottom: 25px;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 40px;
    outline: none;
    /* border: 2px solid #ccc; */
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
    width: 150px;
    height: 48px;
    background: #1d1d20; 
    border: none;
    outline: none;
    cursor: pointer;
}

.signin_facebook_button:hover {
    background: #677ed8;
    color: #FCFCFD;
    transition: all 0.3s ease-in-out; 
}

.signin_or {
    position: relative;
    margin-bottom: 1.5rem;
}

.signin_or_line {
    width: 450px;
}

.signin_or_text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    font-weight: 400;
    /* color: #fff; */
    margin-bottom: 1.5rem;
    color: #777E90;
    background-color: #fff;
    padding: 1rem;
    font-size: 16px;
    line-height: 24px;
    word-spacing: 0.6px;
    letter-spacing: 0.3px;
    align-items: center;
}

.login_text {
    color: #92A5EF;
    text-decoration: none;
}

.login_text:hover {
    color: #627ada;
    text-decoration: none;
}

.have_account {
    margin-bottom: 2.5rem;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    color: #353945;
}

.password_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem; 
    align-self: center;
}

.forgot_password a {
    color: #fff;
    text-decoration: none;
    background: #475683;
    padding: 15px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.forgot_password a:hover {
    color: #fff;
    background: #233774;
    transition: all 0.3s ease-in-out;
}

.remember_me {
    color: #353945;
}

/* input[type=checkbox] {
    appearance: none;
    -webkit-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px;
    background-color: #353945;
    cursor: pointer;
    align-items: center;
    justify-content: center;
} */

/* input[type=checkbox]:hover {
    background: #23262F;
} */

.checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkbox_input {
    display: none;
}

.checkbox_box {
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #353945;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;
    transition: background 0.15s, border-color 0.15s;
}

.checkbox_box::after {
    content: "\2714";
    color: #fff;
    transform: scale(0);
    transition: transform 0.15s;
}

.checkbox_input:checked + .checkbox_box {
    background: #353945;
    
}

.checkbox_input:checked + .checkbox_box::after {
    transform: scale(1);
}

/* label:before {
    border-radius: 3px;
} */

input[type=checkbox]:checked {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    color: #f3f3f3;
    text-align: center;
    line-height: 15px;
}

@media screen and (max-width: 700px) {
    .signin_or_line {
        width: 400px;
    }
    .signin_or_text {
        font-size: 0.9rem;
        padding: 0.6rem;
    }
    .signin_input {
        width: 400px;
    }
}
@media screen and (max-width: 550px) {
    .signin_or_line {
        width: 300px;
    }
    .signin_or_text {
        font-size: 0.9rem;
        width: 200px;
    }
    .signin_input {
        width: 300px;
    }
    .password_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .remember_me, .forgot_password {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 400px) {
    .signin_google_button, .signin_facebook_button {
        width: 120px;
        font-size: 0.8rem;
    }
    .signin_input {
        width: 300px;
    }
}

@media screen and (max-width: 345px) {  
    .signin_input {
        width: 250px;
        font-size: 0.8rem;
    }
    .signin_google_button, .signin_facebook_button {
        display: block;
        margin-bottom: 0.5rem;
    }
    .signin_label {
        font-size: 12px;
    }
    .have_account {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 300px) {  
    .signin_header {
        font-size: 2.5rem;
    }
    .signin_title {
        font-size: 0.8rem;
    }
    .signin_input {
        width: 200px;
        font-size: 0.7rem;
    }
}