.add_announcement_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 2rem auto;
    justify-content: center;
}

.editor {
    max-width: 800px;
    border: 2px solid;
    height: 400px;
    overflow: hidden;
}

.rdw-editor-wrapper {
    /* box-sizing: content-box; */
    width: 100%;
    height: 80%;
    /* overflow: hidden; */
}