body {
  scroll-behavior: auto;
}

.landing_page_header {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  opacity: 0.85;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #444;
  background-image: linear-gradient(
      90deg,
      rgba(93, 86, 217, 1),
      rgba(4, 183, 219, 1)
    ),
    url("../../images/ibadurahman.jpeg");
  background-size: cover;
  background-position: initial;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* .landing_page_image {
    background-image: url("../../images/pexels-pixabay-220502.jpg");
    background-size: cover;
    background-position: initial;
    background-repeat: no-repeat;
    background-attachment: fixed;
} */

.landing_page_header_text {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* .landing_page_header_text p {
    font-size: 20px;
} */

.landing_page_header_description {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 17px;
  text-align: center;
  /* word-wrap: break-word; */
}

.landing_page_header_sub {
  font-size: 1rem;
  color: #07599f;
  margin-bottom: 3px;
  text-align: center;
  /* word-wrap: break-word; */
}

.landing_page_header_title {
  font-size: 2.5rem;
  color: #07599f;
  text-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

.button {
  padding: 15px 25px;
  margin: 1rem 0;
  border: 3px solid #07599f;
  color: #07599f;
  background-color: #fff;
  border-radius: 40px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button:hover {
  color: #fff;
  background-color: #07599f;
}

.about_us {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 30px;
  margin-top: 5rem;
}

.row {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subheading {
  font-size: 2rem;
  color: #07599f;
  text-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
  font-weight: bold;
  margin: 10px 0;
}

.row p {
  font-size: 1rem;
  color: rgb(27, 27, 27);
  margin-bottom: 20px;
  word-spacing: 10px;
}

.our_missions {
  background-image: url("../../images/wave(2).svg");
  background-size: cover;
  background-attachment: fixed;
  padding: 30px;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
}

.our_mission_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #ffffff;
  padding: 30px;
  transition: all 0.3s ease-in-out;
  margin: 0.75rem;
}

.review_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 30px;
  transition: all 0.3s ease-in-out;
  margin: 0.75rem;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.form_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #ffffff;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  margin: 0.75rem;
  width: 100%;
}

.card:hover,
.review_card:hover,
.card2:hover,
.form_card:hover {
  box-shadow: 0px 0px 30px 0px rgba(59, 151, 206, 0.1);
  transform: translateY(4px);
}

.card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  background: #ffffff;
  margin: 0.75rem;
  width: 170px;
  height: 170px;
  padding: 15px;
  transition: all 0.3s ease-in-out;
}

.card2 h3 {
  text-align: center;
  color: rgb(39, 39, 39);
}

.image_logo {
  width: 100%;
  max-width: 450px;
  height: 100%;
}

.our_services {
  background: #1f8efa;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.our_services_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
}

.our_services_subheading {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
  font-weight: bold;
  margin: 10px 0;
}

.our_services_description {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 20px;
  /* word-wrap: break-word; */
}

.tag_container {
  background: #1f8efa;
  color: #fff;
  font-size: 30px;
  padding: 15px 20px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.contact_us {
  background-image: url("../../images/wave(2).svg");
  background-size: cover;
  background-attachment: fixed;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.contact_us_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
}

.contact_us_row {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.input,
.text_area {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.5);
  margin: 10px 0 15px;
  transition: all 0.3s ease-in-out;
  resize: none;
  width: 100%;
}

.input:hover,
.input:focus {
  border: 2px solid #1f8efa;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.carousel_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80% !important;
  margin: 5rem auto 3rem auto;
  /* overflow: hidden; */
}

.carousel_container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80% !important;
  margin: 5rem auto 3rem auto;
  /* overflow: hidden; */
}

.carousel_container2 .slider-wrapper {
  background-color: #0093e9 !important;
  background-image: linear-gradient(
    160deg,
    #0093e9 0%,
    #80d0c7 100%
  ) !important;
}

.carousel {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  flex-wrap: wrap;
}

.user_image {
  width: 150px !important;
  height: 150px !important;
  border-radius: 50%;
}

.review_description {
  color: rgb(0 35 35);
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.review_name {
  color: rgb(0 35 35);
  font-weight: 500;
  font-size: 1rem;
  margin-top: -0.5rem;
}

.core_values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Poppins";
}

.core_values li {
  font-size: 1rem;
}

.mission__card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.mission__card__title {
  font-size: 1.5rem;
  color: #07599f;
  text-shadow: 0px 0px 10px rgba(216, 216, 216, 0.5);
  font-weight: bold;
  margin: 7px 0;
}

@media screen and (max-width: 750px) {
  .about_us {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .row {
    width: 90%;
    margin: 1.5rem auto;
  }

  .contact_us_row {
    width: 90%;
    margin: 1.5rem auto;
  }
}

@media screen and (max-width: 450px) {
  .card {
    width: 100%;
  }

  .review_card {
    width: 100%;
  }

  .landing_page_header_description {
    font-size: 1.2rem;
  }

  .landing_page_header_title {
    font-size: 1.8rem;
  }

  .landing_page_header_sub {
    font-size: 0.9rem;
  }
}
