.announcement_page {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.header {
    font-size: 30px;
}

.news {
    font-size: 20px;
}

.news_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.news_card {
    max-width: 300px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 2px solid #ccc;
    border-radius: 20px;
    overflow: hidden;
}

.announcement_image_container {
    overflow: hidden;
    width: 100%;
    height: 200px;
}

.announcement_image {
    width: 100%;
    height: 100%;
}

.details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.title {
    font-size: 18px;
    color: rgb(24, 24, 24);
}

button {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0.7rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

button:hover {
    color: #fff;
    background-color: #07599f;
}

.navigation {
    margin: 2rem auto;
}

.navigation_button {
    font-size: 14px;
    padding: 10px 20px;
    margin: 1rem 1rem;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.navigation_button:hover {
    color: #fff;
    background-color: #07599f;
}


.anchor {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0.7rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.anchor:hover {
    text-decoration: none;
    color: #fff;
    background-color: #07599f;
}

@media screen and (max-width: 350px) {
    .header {
        font-size: 20px;
    }

    .title {
        font-size: 15px;
    }
}