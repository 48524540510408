.staff_page {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
}

.header {
    font-size: 30px;
    margin: 3.5rem 0;
}

.staff_card_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.staff_card {
    max-width: 250px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px 30px;
}

.staff_page_image_container {
    overflow: hidden;
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.staff_image {
    width: 100%;
    height: 100%;
}

.details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.staff_name {
    font-size: 20px;
    color: rgb(24, 24, 24);
    margin: 1rem 0;
}

.staff_position {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: rgb(179, 12, 12);
}

button {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0.7rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

button:hover {
    color: #fff;
    background-color: #07599f;
}

@media screen and (max-width: 350px) {
    .header {
        font-size: 20px;
    }

    .staff_name {
        font-size: 15px;
    }

    .staff_page_image_container {
        overflow: hidden;
        border-radius: 50%;
        width: 150px;
        height: 150px;
    }
}