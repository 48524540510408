.admin_dashboard_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 2rem auto;
    justify-content: center;
}

.box_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
}

.box_div {
    width: 150px;
    height: 150px;
    color: white !important;
    background-color: #07599f;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 7px;
    padding: 10px;
    text-align: center;
}

.box_div h3 {
    color: #fff;
}

.admin_dashboard_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 50px;
}