.signin_container {
    max-width: 1000px;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    align-content: center;
}

.signin_word {
    /* font-size: 2.5rem;
    /* color: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    /* padding: 2rem; */
    /* margin: auto; */
    align-self: center;
}

.signin_header {
    font-size: 2rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    /* color: #fff; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 48px;
    text-align: center;
    letter-spacing: 1px;
    color: #141416;
    text-align: center;
}

.signin_title {
    font-size: 1rem;
    font-weight: 400;
    /* color: #fff; */
    margin-bottom: 0.3rem;
    color: #777E90;
    font-size: 16px;
    line-height: 24px;
    word-spacing: 0.6px;
    letter-spacing: 0.3px;
    text-align: center;
}

.image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 40%
}

.readmore_image {
    width: 100%;
    height: 100%
}

.readmore_content_container {
    width: 80%;
}

.readmore_content {
    text-align: center;
}

@media screen and (max-width: 500px) {
    .signin_header {
        font-size: 1.5rem;
    }

    .signin_title {
        font-size: 0.85rem;
    }
}