.gallery_page {
    max-width: 1100px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.header {
    font-size: 30px;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.card_container {
    position: relative;
    max-width: 250px;
    width: 250px;
    margin: 1rem;
    border-radius: 20px;
}

.gallery_image_pic {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.card_container:hover .overlay {
    opacity: 1;
}

.text {
    color: white;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.button {
    font-size: 14px;
    padding: 10px 20px;
    margin: 1.5rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.button:hover {
    color: #fff;
    background-color: #07599f;
}

@media screen and (max-width: 400px) {
    .card_container {
        position: relative;
        max-width: 200px;
        width: 200px;
        margin: 0.8rem;
        border-radius: 10px;
    }

    .text {
        font-size: 13px;
    }
}

@media screen and (max-width: 350px) {
    .header {
        font-size: 20px;
    }
}