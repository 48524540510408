.signin_container {
    max-width: 1000px;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    align-content: center;
}

.signin_word {
    /* font-size: 2.5rem;
    /* color: #fff; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    /* padding: 2rem; */
    /* margin: auto; */
    align-self: center;
}

.signin_header {
    font-size: 3rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    /* color: #fff; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 48px;
    text-align: center;
    letter-spacing: 1px;
    color: #141416;
}

.signin_title {
    font-size: 1rem;
    font-weight: 400;
    /* color: #fff; */
    margin-bottom: 0.3rem;
    color: #777E90;
    font-size: 16px;
    line-height: 24px;
    word-spacing: 0.6px;
    letter-spacing: 0.3px;
}

button {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0.7rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

button:hover {
    color: #fff;
    background-color: 07599f;
}

.anchor {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0.7rem 0;
    border: 3px solid #07599f;
    color: #07599f;
    background-color: #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.anchor:hover {
    text-decoration: none;
    color: #fff;
    background-color: #07599f;
}

table {
    align-items: center;
    justify-content: center;
    text-align: center;
}

td {
    padding: 5px 20px;
}

.gallery_image {
    width: 150px;
    height: 150px;
}

@media screen and (max-width: 700px) {
    .signin_or_line {
        width: 400px;
    }

    .signin_or_text {
        font-size: 0.9rem;
        padding: 0.6rem;
    }

    .signin_input {
        width: 400px;
    }
}

@media screen and (max-width: 550px) {
    .signin_or_line {
        width: 300px;
    }

    .signin_or_text {
        font-size: 0.9rem;
        width: 200px;
    }

    .signin_input {
        width: 300px;
    }

    .password_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .remember_me,
    .forgot_password {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 400px) {

    .signin_google_button,
    .signin_facebook_button {
        width: 120px;
        font-size: 0.8rem;
    }

    .signin_input {
        width: 300px;
    }
}

@media screen and (max-width: 345px) {
    .signin_input {
        width: 250px;
        font-size: 0.8rem;
    }

    .signin_google_button,
    .signin_facebook_button {
        display: block;
        margin-bottom: 0.5rem;
    }

    .signin_label {
        font-size: 12px;
    }

    .have_account {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 300px) {
    .signin_header {
        font-size: 2.5rem;
    }

    .signin_title {
        font-size: 0.8rem;
    }

    .signin_input {
        width: 200px;
        font-size: 0.7rem;
    }
}